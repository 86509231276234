import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import favicon from '../../static/favicon.ico';
import { withEndSlash } from '../lib';

const getSchemaOrgJSONLD = ({
  siteMetadata,
  url,
  title,
  image,
  blogPost /*: {
    description,
    author,
    datePublished,
  }*/,
}) => {
  const organization = {
    '@type': 'Organization',
    url: siteMetadata.siteUrl,
    name: 'YOU are safe online',
    logo: {
      '@type': 'ImageObject',
      url: `${siteMetadata.siteUrl}/android-chrome-512x512.png`,
    },
    // sameAs: [
    //   'https://twitter.com/NAME',
    //   'https://www.facebook.com/NAME',
    //   'https://www.youtube.com/channel/UCyJZcY5SnI8UJT993PFS57Q',
    //   'https://www.instagram.com/NAME/',
    //   'https://www.linkedin.com/company/NAME/',
    //   'https://github.com/NAME',
    // ],
  };

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: siteMetadata.title,
    },
    {
      '@context': 'http://schema.org',
      ...organization,
    },
  ];

  if (blogPost) {
    return [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': siteMetadata.siteUrl,
              name: 'YOU are safe online',
              image: `${siteMetadata.siteUrl}/android-chrome-512x512.png`,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': url,
              name: title,
              image: image.src,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: siteMetadata.title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image.src,
        },
        description: blogPost.description,
        author: {
          '@type': 'Person',
          name: blogPost.author,
          affiliation: organization,
          brand: organization,
          worksFor: organization,
        },
        publisher: organization,
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': siteMetadata.siteUrl,
        },
        datePublished: blogPost.datePublished,
        dateModified: blogPost.dateModified,
      },
    ];
  }

  return schemaOrgJSONLD;
};

const SEO = ({
  title,
  description,
  url,
  //optionals
  image,
  blogPost,
  noIndex = false,
}) => (
  <StaticQuery
    query={detailsQuery}
    render={({ site: { siteMetadata } }) => {
      const pageTitle = title || siteMetadata.title;
      const fullUrl = withEndSlash(
        `${siteMetadata.siteUrl}/${
          url ? (url.indexOf('/') === 0 ? url.substring(1) : url) : ''
        }`
      );
      const imageUrl = `${siteMetadata.siteUrl}${
        image ? image.src : '/logo.png'
      }`;

      const schemaOrgJSONLD = getSchemaOrgJSONLD({
        siteMetadata,
        url: fullUrl,
        title: pageTitle,
        blogPost,
        image,
      });
      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/ico',
              href: `${favicon}`,
            },
          ]}
        >
          {noIndex && <meta name="robots" content="noindex" />}

          {/* General tags */}
          <title>
            {pageTitle}
            {pageTitle !== siteMetadata.title ? ` - ${siteMetadata.title}` : ''}
          </title>
          <link rel="canonical" href={fullUrl} />
          <meta charSet="UTF-8" />
          <meta name="description" content={description} />
          <meta name="image" content={imageUrl} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5b7024" />
          <meta name="msapplication-TileColor" content="#5b7024" />
          <meta name="theme-color" content="#b6cadb" />

          {/* Schema.org tags */}
          {title && (
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          )}

          {/* OpenGraph tags */}
          <meta property="og:url" content={fullUrl} />
          {blogPost ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta
            property="og:image:width"
            content={image ? image.width : '1200'}
          />
          <meta
            property="og:image:height"
            content={image ? image.height : '628'}
          />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          {/*<meta name="twitter:site" content={twitterLink} />*/}
          {/*<meta name="twitter:creator" content={twitterLink} />*/}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
      );
    }}
  />
);

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
