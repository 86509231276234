import clsx from 'clsx';
import { graphql, Link, StaticQuery } from 'gatsby';
import React, { useState } from 'react';

import logo from '../../static/logo-small.svg';
import { withEndSlash } from '../lib';

const HeaderLink = ({ className, to, children, ...props }) => {
  className = clsx(
    `w-full md:w-auto 
    py-2 md:px-1 
    -mb-1 
    text-sm font-medium uppercase 
    hover:text-secondary-200 
    border-transparent border-b-4 md:hover:border-secondary-200 
    relative`,
    className
  );

  return (
    <div className="flex px-1 font-emp">
      {to.startsWith('http') ? (
        <a className={className} href={to} rel="noopener noreferrer" {...props}>
          {children}
        </a>
      ) : (
        <Link
          className={className}
          to={withEndSlash(to)}
          {...props}
          activeClassName="md:border-secondary-200"
        >
          {children}
        </Link>
      )}
    </div>
  );
};

const Header = ({ className, data, ...props }) => {
  let [isExpanded, toggleExpansion] = useState(false);

  const { menuLinks } = data.site.siteMetadata;
  className = clsx('w-full shadow-md', className, isExpanded && 'expanded');

  return (
    <div className={className} {...props}>
      <div
        className={`flex flex-wrap flex-row items-start md:items-center justify-between 
        px-6 md:px-10 lg:px-24 
        text-primary-300`}
      >
        <div className="my-2">
          <Link to="/">
            <img
              style={{ height: '2rem' }}
              alt="YOU are safe online Homepage"
              src={logo}
            />
          </Link>
        </div>

        <button
          className="block relative md:hidden border border-primary-300 my-2 px-3 py-2"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            style={{ width: '0.75rem' }}
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full md:w-auto flex md:flex flex-col md:flex-row justify-center md:justify-end items-stretch md:items-center py-1`}
        >
          <HeaderLink to="/search/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '1.25rem' }}
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </HeaderLink>
          {menuLinks.map((link) => (
            <HeaderLink key={link.name} to={link.link} target={link.target}>
              {link.name}
            </HeaderLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);
