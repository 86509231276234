import React, { useEffect } from 'react';
import 'typeface-architects-daughter';
import 'typeface-ubuntu';

import { uuidv4 } from '../lib';
import '../scss/style.scss';
import Footer from './Footer';
import Header from './Header';

const Layout = ({
  className,
  headerClass,
  bodyClass,
  shouldShowChat = false,
  children,
  lpMode = false,
  ...props
}) => {
  useEffect(() => {
    if (!window) return;

    if (!localStorage.getItem('userId')) {
      localStorage.setItem('userId', uuidv4());
    }
  }, []);

  return (
    <div className={className} {...props}>
      {!lpMode && <Header className={headerClass} />}
      <div className={bodyClass}>{children}</div>
      {!lpMode && <Footer />}
    </div>
  );
};

export default Layout;
