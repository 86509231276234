import clsx from 'clsx';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';

import { formatTimeUntilNow } from '../lib';

const BlogPostPreview = ({
  className,
  node: { excerpt, timeToRead, path, image, title, publishedDate, author },
  displayAuthor,
}) => (
  <div className={clsx('blog-post-preview', className)}>
    <Link to={path} className="no-underline">
      <div className="flex-1 -mx-6 -mt-10 mb-4">
        <BackgroundImage
          Tag="div"
          className="blog-post-image"
          style={{ backgroundSize: 'contain' }}
          fluid={image.childImageSharp.fluid}
        >
          {' '}
        </BackgroundImage>
      </div>

      <div className="p-1">
        <h3>{title}</h3>
        <p className="text-xs text-gray-500">
          {formatTimeUntilNow(publishedDate)}
          {displayAuthor ? ` by ${author.frontmatter.name}` : ''}
          {` - ${timeToRead} min read`}
        </p>
        <div className="mb-2">{excerpt}</div>

        <p className="underline">Read more...</p>
      </div>
    </Link>
  </div>
);

export default BlogPostPreview;
