import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';

import logo from '../../static/logo.svg';

const Footer = ({ className, ...props }) => {
  className = clsx(
    'w-full px-8 py-4 text-white bg-gray-800 flex flex-col md:flex-row justify-between items-start md:items-center',
    className
  );

  return (
    <>
      <div className={'w-full px-8 py-4 text-white bg-gray-800 text-center'}>
        This website contains affiliate links. This website uses cookies to
        enhance the user experience. Check the Privacy policy for more details.
      </div>

      <div className={className} {...props}>
        <div className="flex-initial self-center mt-3 mb-6 md:my-auto">
          <img className="h-12" alt="YOU are safe online Logo" src={logo} />
        </div>
        <div className="self-center">
          <ul className="flex flex-col md:flex-row text-sm -mx-3 font-medium text-center">
            <li className="mx-3">
              <Link to="/privacy-policy">Privacy policy</Link>
            </li>
            <li className="mx-3">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li className="mx-3">
              <Link to="/about">About</Link>
            </li>
            <li className="mx-3">
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-black flex flex-col sm:flex-row justify-between items-start md:items-center">
        <ul className="flex flex-col lg:flex-row -mx-3">
          <li className="mx-3 -mr-2">
            <strong className="font-bold whitespace-no-wrap">
              Copyright © 2020 YOU are safe online.
            </strong>
          </li>
          <li className="mx-3">
            <strong className="font-bold">All Rights Reserved.</strong>
          </li>
        </ul>

        <ul className="flex flex-row flex-wrap justify-end -ml-1 my-2 sm:my-auto text-xl font-semibold">
          <li className="mx-1">
            <a
              href="https://www.facebook.com/youaresafe.online"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} className="mr-1" />
            </a>
          </li>

          <li className="ml-3">
            <a href="https://youaresafe.online">youaresafe.online</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
