import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ro';

const locale =
  typeof window !== 'undefined' &&
  (window.navigator.userLanguage || window.navigator.language);
moment.locale(locale);

export function formatTimeUntilNow(date) {
  return moment(date).fromNow();
}
